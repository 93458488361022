import { render, staticRenderFns } from "./TheTable.vue?vue&type=template&id=681f30ad&scoped=true"
import script from "./TheTable.vue?vue&type=script&lang=js"
export * from "./TheTable.vue?vue&type=script&lang=js"
import style0 from "./TheTable.vue?vue&type=style&index=0&id=681f30ad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681f30ad",
  null
  
)

export default component.exports